import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { H2, Link } from "../components/basic";
import Card from "../components/card";

const Metadata = () => (
  <Helmet>
    <meta name="og:type" content="profile" />
    <meta name="profile:first_name" content="Jose" />
    <meta name="profile:last_name" content="Gonzalez" />
    <meta name="profile:gender" content="male" />
  </Helmet>
);

const Index = ({ data }) => {
  const JoseGonzalezImage = (
    <GatsbyImage
      alt="Jose Gonzalez"
      title="Jose Gonzalez"
      image={data.joseGonzalez.childImageSharp.gatsbyImageData}
    />
  );

  const ComputerImage = (
    <GatsbyImage
      alt="Computer"
      title="Software"
      image={data.computer.childImageSharp.gatsbyImageData}
    />
  );

  const BarcelonaImage = (
    <GatsbyImage
      alt="F. C. Barcelona"
      title="I support Barça"
      image={data.barcelona.childImageSharp.gatsbyImageData}
    />
  );

  return (
    <Layout title="Jose Gonzalez">
      <Metadata />
      <Card media={JoseGonzalezImage}>
        I am a software engineer based in Leeds, UK. I graduated from Granada
        University (Spain) with an MSc in Computer Science Engineering.
      </Card>
      <H2>Software</H2>
      <Card media={ComputerImage}>
        {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
        I am passionate about open source, and I like to give back to the community, as you can see in my <Link to="/portfolio/">portfolio</Link>. This has also helped me professionally, as I believe that by writing software that’s intended for others to use, you tend to architect it in a way that’s readable, decoupled, tested and, in general, follows best practices. On top of that, other contributors can fix it, extend it and improve it, and by doing so, you learn and grow as a developer.
      </Card>
      <H2>Personal</H2>
      <Card media={BarcelonaImage}>
        {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
        When I’m not working, I enjoy travelling (who doesn’t?) and I love playing any sort of sport, football and weightlifting in particular. As I’m sure you have noticed, I am a Barça fan <span role="img" aria-label="Grinning Face">😀</span>.
      </Card>
    </Layout>
  );
};

Index.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query {
    joseGonzalez: file(relativePath: { eq: "jose-gonzalez.jpg" }) {
      ...CardImage
    }
    computer: file(relativePath: { eq: "computer.jpg" }) {
      ...CardImage
    }
    barcelona: file(relativePath: { eq: "barcelona.jpg" }) {
      ...CardImage
    }
  }
`;
